import React, { Suspense, useEffect, useState } from 'react';
import styles from "./App.module.css";
import { useLoadScript } from "@react-google-maps/api";
import { ErrorFallback, ModalWindows, ToastList } from "@shared/components";
import { Loader } from "@ui-kit";
import { ErrorBoundary } from "react-error-boundary";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { MessageStoreComp } from "@shared/components/message-store-comp/message-store.comp";
import { notificationsStore } from "./store/notifications.store";
import { userStore } from "./store/user.store";
import { MainPage } from "./main-page/main.page";
import { register } from 'swiper/element/bundle';
import { Container } from "@ui-kit/components/container/container.comp";
import { apolloClient } from "./apollo-client";
import { onlineHeartbeat } from "@graphql/docs/mutations";
import { Content } from "@shared/components/content/content.comp";
import { UserMenu } from "@shared/components/user-menu/user-menu.comp";
import { MessageNotifications } from "@shared/components/message-notifications/message-notifications.comp";
import { requestPermission, showNotification } from "@shared/helpers/notification";
import { MainMenu } from "./main-page/components/main-menu/main-menu.comp";
import { MainRight } from "./main-page/components/main-right/main-right.comp";
import { useDayJsLocale } from './main-page/components/post/helpers/useDayJsLocale';


const NotFoundPage = React.lazy(() => import(/* webpackChunkName: 'not-found-page' */ './not-found-page/not-found.page'));
const HelpPage = React.lazy(() => import(/* webpackChunkName: 'help-page' */ './help-page/help.page'));
const AdminPanelReporterPage = React.lazy(() => import(/* webpackChunkName: 'admin-panel-reporter-page' */ './admin-panel-pages/pages/reporter-page/reporter.page'));
const AdminPanelReportsPage = React.lazy(() => import(/* webpackChunkName: 'admin-panel-reports-page' */ './admin-panel-pages/pages/reports-page/admin.reports.page'));
const AdminPanelUsersPage = React.lazy(() => import(/* webpackChunkName: 'admin-panel-users-page' */ './admin-panel-pages/pages/users-page/admin.users.page'));
const AdminPanelPage = React.lazy(() => import(/* webpackChunkName: 'admin-panel-page' */ './admin-panel-pages/admin.panel.page'));
const NotificationsPage = React.lazy(() => import(/* webpackChunkName: 'notifications-page' */ './notifications-page/notifications.page'));
const AgreementPage = React.lazy(() => import(/* webpackChunkName: 'agreement-page' */ './agreement-page/agreement.page'));
const SavedPostsPage = React.lazy(() => import(/* webpackChunkName: 'saved-posts-page' */ './saved-posts-page/saved-posts.page'));
const MessengerPage = React.lazy(() => import(/* webpackChunkName: 'messenger-posts-page' */ './messenger-page/messenger.page'));
const MapPage = React.lazy(() => import(/* webpackChunkName: 'map-page' */ './map-page/map.page'));
const ProfilePage = React.lazy(() => import(/* webpackChunkName: 'profile-page' */ './profile-page/profile.page'));
const SearchPage = React.lazy(() => import(/* webpackChunkName: 'search-page' */ './search-page/search.page'));
const PostPage = React.lazy(() => import(/* webpackChunkName: 'post-page' */ './post-page/post.page'));
const FollowPage = React.lazy(() => import(/* webpackChunkName: 'follow-page' */ './follow-page/follow.page'));
const UserWallPage = React.lazy(() => import(/* webpackChunkName: 'user-wall-page' */ './user-wall-page/user-wall.page'));
const LoginPage = React.lazy(() => import(/* webpackChunkName: 'auth-login-page' */ './auth-pages/pages/login-page/login.page'));
const RegistrationPage = React.lazy(() => import(/* webpackChunkName: 'auth-registration-page' */ './auth-pages/pages/registration-page/registration.page'));
const VerifyRecoverPage = React.lazy(() => import(/* webpackChunkName: 'verify-recover-page' */ './auth-pages/pages/verify-recover-page/verify-recover.page'));
const VerifyRegistrationPage = React.lazy(() => import(/* webpackChunkName: 'verify-registration-page' */ './auth-pages/pages/verify-registration-page/verify-registration.page'));
const DeleteAccountPage = React.lazy(() => import(/* webpackChunkName: 'delete-account-page' */ './delete-account-page/delete-account.page'));
const PrivacyPolicyPage = React.lazy(() => import(/* webpackChunkName: 'privacy-policy-page' */ './privacy-policy-page/privacy-policy.page'));
const TermsUsePage = React.lazy(() => import(/* webpackChunkName: 'terms-use-page' */ './terms-use-page/terms-use.page'));


register();

function App() {

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_KEY ?? '',
    language: 'en',
    libraries: ['places'],
  });

  useDayJsLocale();

  const userMenuState = useState(false);
  const [isOverlay, setIsOverlay] = useState(false);
  const HEARTBIT_INTERVAL_MS = 60_000;
  useEffect(() => {
    userStore.currentUser = undefined;
    userStore.me().finally(() => {
      notificationsStore
        .getNotificationLength()
        .catch();
    });

    setTimeout(() => {
      setInterval(() => {
        if(userStore.currentUser) {
          apolloClient.mutate({ mutation: onlineHeartbeat })
            .catch()
        }
      }, HEARTBIT_INTERVAL_MS)
    }, HEARTBIT_INTERVAL_MS)
  }, []);

  const REQUEST_PERMISSION_TIMEOUT_MS = 5_000;
  setTimeout(() => {
    requestPermission();
  }, REQUEST_PERMISSION_TIMEOUT_MS);

  const handleOverlay = (overlay: boolean) => {
    setIsOverlay(overlay);
  };

  useEffect(() => {
    notificationsStore.createStream();
  }, [userStore.currentUser])

  if (!isLoaded) return <Loader />;
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <div className={`${styles['overlay']} ${isOverlay ? styles['overlay_active'] : ''}`} onClick={() => setIsOverlay(false)}></div>
      <>
        <Routes>
          <Route path="/login" element={<Suspense fallback={<Loader />}><LoginPage /></Suspense>} />
          <Route path="/registration" element={<Suspense fallback={<Loader />}><RegistrationPage /></Suspense>} />
          <Route path="/registration/verify" element={<Suspense fallback={<Loader />}><VerifyRegistrationPage /></Suspense>} />
          <Route path="/verify-recover" element={<Suspense fallback={<Loader />}><VerifyRecoverPage/></Suspense>} />
          <Route path="*" element={<>
            <Container className={styles.container}>
              <MainMenu userMenuState={userMenuState} />
              <Content className={styles['main-page']}>
                <MessageStoreComp />
                <Routes>
                  <Route path="/" element={<MainPage onOverlay={handleOverlay}/>} />
                  <Route path="/messages" element={<Suspense fallback={<Loader />}><MessengerPage /></Suspense>} />
                  <Route path="/search" element={<Suspense fallback={<Loader />}><SearchPage /></Suspense>} />
                  <Route path="/profile" element={<Suspense fallback={<Loader />}><ProfilePage /></Suspense>} />
                  <Route path="/post/:postId" element={<Suspense fallback={<Loader />}><PostPage /></Suspense>} />
                  <Route path="/map/:mapType" element={<Suspense fallback={<Loader />}><MapPage /></Suspense>} />
                  <Route path="/wall/:username" element={<Suspense fallback={<Loader />}><UserWallPage /></Suspense>} />
                  <Route path="/followers/:username" element={<Suspense fallback={<Loader />}><FollowPage isFollowing={false} /></Suspense>} />
                  <Route path="/following/:username" element={<Suspense fallback={<Loader />}><FollowPage isFollowing={true} /></Suspense>} />
                  <Route path="/saved" element={<Suspense fallback={<Loader />}><SavedPostsPage /></Suspense>} />
                  <Route path="/agreement" element={<Suspense fallback={<Loader />}><AgreementPage /></Suspense>} />
                  <Route path="/notifications" element={<Suspense fallback={<Loader />}><NotificationsPage /></Suspense>} />
                  <Route path="/admin" element={<Suspense fallback={<Loader />}><AdminPanelPage /></Suspense>} />
                  <Route path="/admin/users" element={<Suspense fallback={<Loader />}><AdminPanelUsersPage /></Suspense>} />
                  <Route path="/admin/reports" element={<Suspense fallback={<Loader />}><AdminPanelReportsPage /></Suspense>} />
                  <Route path="/admin-reporter/:username" element={<Suspense fallback={<Loader />}><AdminPanelReporterPage /></Suspense>} />
                  <Route path="/help" element={<Suspense fallback={<Loader />}><HelpPage /></Suspense>} />
                  <Route path="/profile/delete-account" element={<Suspense fallback={<Loader />}><DeleteAccountPage /></Suspense>} />
                  <Route path="/privacy-policy" element={<Suspense fallback={<Loader />}><PrivacyPolicyPage /></Suspense>} />
                  <Route path="/terms" element={<Suspense fallback={<Loader />}><TermsUsePage /></Suspense>} />
                  <Route path="*" element={<Suspense fallback={<Loader />}><NotFoundPage /></Suspense>}/>
                </Routes>
              </Content>
              <MainRight />
            </Container>
            <UserMenu userMenuState={userMenuState}
              user={userStore.currentUser}
              onOverlay={handleOverlay} />
          </>}/>
        </Routes>
        <ToastList />
        <ModalWindows />
        <MessageNotifications />
      </>
    </ErrorBoundary>
  );
}

export default App;

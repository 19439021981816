import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import "dayjs/locale/ru";
import "dayjs/locale/en";
import "dayjs/locale/kk";

dayjs.extend(relativeTime);

export const useDayJsLocale = () => {
  const { i18n } = useTranslation();

  useEffect(() => {
    const getLocale = () => {
      const lang = i18n.language ?? navigator.language.split("-")[0];
      return ["ru", "en", "kk"].includes(lang) ? lang : "ru";
    };
    const updateLocale = () => {
      const locale = getLocale();
      dayjs.locale(locale);
    };
    updateLocale();

    i18n.on("languageChanged", updateLocale);

    return () => {
      i18n.off("languageChanged", updateLocale);
    };
  }, [i18n]);
};
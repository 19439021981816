import { registrationStore, userStore } from "@store";

export const handleSendCode = async () => {
  const userData = registrationStore.getData();
  const city = registrationStore.getCity();

  try {
    const newUserData = await userStore.newUser({
      username: userData.username,
      phone: userData.phone,
      email: userData.email,
      firstname: userData.firstname,
      lastname: userData.lastname,
      password: userData.password,
      city: city ?? {
        name: 'unknown',
        place_id: 'unknown',
      },
      whatsapp: registrationStore.whatsapp,
      byEmail: registrationStore.emailapp,
    });

    if (newUserData) {
      registrationStore.setIsSendedCode(true);
    } else {
      console.error('Ошибка создания узла пользователя?');
    }
  } catch (error) {
    console.error('Ошибка при отправке запроса:', error);
  }
};
import styles from "./tabs.module.css";

export type TabItem = {
  key: string
  title: string,
  disabled?: boolean,
}

type Props = {
  items: TabItem[],
  className?: string,
  current?: TabItem,
  onClick?: (key: string) => void
  size?: 'sm'|'md',
  type?: 'default'|'auth',
}

export const Tabs = ({ items, className, current, onClick, size, type = 'default' }: Props) => {
  size = size ?? 'md'
  className = className ? `${styles.tabs} ${className}` : styles.tabs;
  className = `${className} ${styles[`tabs-${size}`]} ${styles[`tabs-${type}`]}`;

  return (<ul className={className}>
    {items.map((item) => (
      <li
        key={item.key}
        className={`${type === 'auth' ? styles['item-auth'] : styles.item }
          ${ item.key === current?.key ? (type === 'auth' ? styles['item_active-auth'] : styles.item_active) : ''}
          ${!!item.disabled ? styles.item_disabled : ''}`}
        onClick={onClick && !item.disabled ? () => onClick(item.key) : () => {}}
      >
        {item.title}
      </li>
    ))}
  </ul>)
}
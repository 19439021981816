import styles from "./post.module.css";
import { LinkData, Post } from "@graphql/graphql";
import { getCorrectPostDate } from "./helpers/get-correct-post-date";
import { MediaContentComponent } from "./media-content-component/media-content-component";
import { ReactionsComponent } from "./reactions/reactions.comp";
import { useEffect, useRef, useState } from "react";
import { Avatar, Button, Carousel, Tooltips } from "@ui-kit";
import { modalCustomDialogsStore, postListStore, userStore, wallPostsStore } from "@store";
import { PicturesModalWindow } from "./pictures-modal-window/pictures-modal-window.comp";
import { useNavigate } from "react-router-dom";
import { ContentData } from "@shared/components/content-data/content-data.comp";
import { useInView } from "react-intersection-observer";
import { ItemMenu } from "@shared/components/item-menu/item-menu.comp";
import { MenuItem } from "@ui-kit/components/drop-down/modele/menu-item";
import { POST_MENU_KEYS, usePostMenu } from "./helpers/usePostMenu";
import { hideService } from "@shared/services/hide.service";
import { ReportModalContent } from "@shared/components/report-modal-content/report-modal-content.com";
import { ShareDialog } from "@shared/components/share-dialog/share-dialog.comp";
import { getApiUrls } from "@shared/data/api.constants";
import { LinkDataComponent } from "@shared/components/link-data/link-data.comp";
import { useTranslation } from "react-i18next";
import { EditPostComponent } from "./edit-post-component/edit.post.comp";
import { observer } from "mobx-react-lite";
import { ReactionsSummaryComponent } from "./reactions-summary/reactions-summary.comp";


type Props = {
  item?: Post,
  hideCommentsButton?: boolean
}

export const PostComponent = observer(({ item, hideCommentsButton }: Props) => {
  const { currentUser } = userStore;
  const navigate = useNavigate();

  const { t } = useTranslation('main');

  const [post, setPost] = useState(item);
  const [showReactions, setShowReactions] = useState(false);
  const [showPost, setShowPost] = useState(true);
  const pictureState = useState(-1);
  const [currentPicture, setCurrentPicture] = pictureState;
  const [fullText, setFullText] = useState(false);
  const [showMoreButton, setShowMoreButton] = useState(false);
  const textRef = useRef<any>(null);

  const isDeleted = !post?.userPicture;

  const [isEditing, setIsEditing] = useState(false);

  const { ref, inView } = useInView({ trackVisibility: true, delay: 2000 });

  const getPostMenu = usePostMenu();

  useEffect(() => {
    setTimeout(() => {
      if (textRef?.current?.scrollHeight && textRef?.current?.offsetHeight ) {
        setShowMoreButton(
          textRef.current.scrollHeight > textRef.current.offsetHeight
        );
      }
    }, 1);
  }, [textRef?.current?.scrollHeight, textRef?.current?.offsetHeight]);

  useEffect(() => {
    if (inView && post?.id) {
      postListStore.addViewPost(post?.id);
    }
  }, [inView]);


  const onMediaClick = (value: number) => { setCurrentPicture(() => value); }
  const openUserWall = () => navigate(`/wall/${post?.username}`)

  const onCancelEdit = () => { setIsEditing(false); }

  const onMenuClick = (menuItem: MenuItem) => {

    switch(menuItem.key as POST_MENU_KEYS) {
      case POST_MENU_KEYS.SAVE:
        if (post?.id) wallPostsStore.savePost(post?.id)
          .then(() => setPost({ ...post, isSaved: true }))
        break;
      case POST_MENU_KEYS.REMOVE:
        if (post?.id) wallPostsStore.removeSavedPost(post?.id)
          .then(() => setPost({ ...post, isSaved: false }))
        break;
      case POST_MENU_KEYS.DELETE:
        modalCustomDialogsStore.showDialog(
          undefined,
          {
            title: t('dialogs.deletePost.title'),
            message: t('dialogs.deletePost.message'),
            firstButtonText: t('dialogs.deletePost.firstButton'),
            firstButtonColor: 'secondary-color',
            secondButtonText: t('dialogs.deletePost.secondButton'),
            secondButtonColor: 'primary',
            secondButtonDestructive: true,
            confirmAction: () => {
              if (post?.id) postListStore.deletePost(post?.id)
                .then((res) => res !== null && setShowPost(false))
            },
          },
        );
        break;
      case POST_MENU_KEYS.REPORT:
        modalCustomDialogsStore.showDialog(
          <ReportModalContent item={post} closeModal={() => modalCustomDialogsStore.hideDialog()} />,
          { title: t('dialogs.reportPost.title')},
        )
        break;
      case POST_MENU_KEYS.FOLLOW:
        if (post?.username) {
          userStore.follow(post?.username)
            .then(() => setPost({ ...post, follow: true }))
        }
        break;
      case POST_MENU_KEYS.UNFOLLOW:
        if (post?.username) {
          userStore.unfollow(post?.username)
            .then(() => setPost({ ...post, follow: false }))
        }
        break;
      case POST_MENU_KEYS.HIDE:
        hideService.hidePost(post?.id)
          .then((res) => {
            if (res) {
              setShowPost(false);
            }
          });
        break;
      case POST_MENU_KEYS.LINK:
        onShareClick();
        break;
      case POST_MENU_KEYS.EDIT:
        setIsEditing(true);
        break;
    }
  }

  const onEditSuccess = () => {
    setIsEditing(false);
  }

  const onShareClick = () => {
    const url = post?.id
      ? `${getApiUrls().HOST_URL}/post/${post.id}`
      : getApiUrls().HOST_URL;
    modalCustomDialogsStore.showDialog(
      <ShareDialog url={url} />,
      {
        title: t('dialogs.sharePost.title'),
        secondButtonText: t('dialogs.sharePost.button'),
        secondButtonColor: 'secondary-color',
      }
    )
  }

  if (post && post.isHiden) {
    return <></>
  }

  return <>
    <div className={`${styles.post} ${showPost ? '' : styles.display_none}`} ref={ref}>
    {!isEditing ? (
    <>
      <div className={styles['post__header']}>
        <Avatar
          size='lg'
          url={post?.userPicture?.picture?.url ?? undefined}
          onClick={openUserWall}
          isDeleted={isDeleted}
          statusIcon={post?.userPicture?.isOnline ? 'online' : undefined} />
        <div className={styles['post__top-right']}>
          <div className={styles['post__name']}>
            <div className={styles['post__name-link']} onClick={openUserWall}>
              {isDeleted ? t('isDeletedUser.title') : `${post?.userPicture?.firstname} ${post?.userPicture?.lastname}`}</div>
            <Tooltips className={styles['post__tooltip']}
              tips={post ? getCorrectPostDate(post).formattedDate : ''}
              positionTips='arrow-up'
              sizeTips='xs'>
                <span className={styles['post__tooltip-text']}>
                  {post ? getCorrectPostDate(post).relativeTime : ''}
                </span>
            </Tooltips>
          </div>
          <div className={styles['post__second-text']}>{post?.userPicture?.city?.longName ?? post?.city?.longName ?? ''}</div>
        </div>
        {!isDeleted && <ItemMenu
          className={styles['post__more-menu']}
          items={post ? getPostMenu(post, userStore.currentUser?.username ?? undefined) : []}
          onClick={onMenuClick} />}
      </div>

      {!!post?.text && <div
        ref={textRef}
        className={fullText ? `${styles['post__content']} ${styles['post__content_full']}` : styles['post__content']}
      >
        <ContentData text={post?.text ?? ''} usernames={Array.isArray(post.answer) ? post.answer : []} />
      </div>}

      {!!post?.linkData?.length && post.linkData.length > 0 &&
        <div className={styles['links-data']}>
          {post.linkData.map((linkData: LinkData) =>
            <LinkDataComponent key={linkData.id} linkData={linkData} />
          )}
        </div>}
      {showMoreButton && <Button
        className={styles['post__more-button']}
        size='lg'
        hierarchy='link-gray'
        onClick={() => setFullText((prev) => !prev)}
      >
        {!fullText ? t('post.showMore.read') : t('post.showMore.hide')}
      </Button>}

      <MediaContentComponent
        onClick={onMediaClick}
        className={styles['media-content']}
        items={post?.media} />

      <Carousel
        onClick={onMediaClick}
        className={styles['carousel']}
        items={post?.media ?? []} />

        {!!post && post.createdAt !== post.updatedAt ? <p className={styles['post-edited-badge']}>{t('post.badge')}</p> : ''}
        {post && <ReactionsSummaryComponent setShowReactions={setShowReactions} item={post} showReactions={showReactions} />}
      <div className={styles['post__bottom']}>
      <div className={styles['post__reactions-conteiner']}
        onMouseEnter={() => !!currentUser && setShowReactions(true)}
        onMouseLeave={() => !!currentUser && setShowReactions(false)}>
        {post && <ReactionsComponent setShowReactions={setShowReactions} item={post} showReactions={showReactions} />}
        <Button className={styles['post__bottom-button']}
          disabled={isDeleted}
          onClick={() => !!currentUser && setShowReactions((prev) => !prev)}
          size='3xl' hierarchy='primary-gray'
          iconType='thumbs-up'>
            {t('post.button.reaction')}
          </Button>
      </div>

        {!hideCommentsButton && <Button className={styles['post__bottom-button']}
          onClick={() => { navigate(`/post/${post?.id}`) }}
          size='3xl'
          hierarchy='primary-gray'
          disabled={isDeleted}
          iconType='message-circle'>
          {t('post.button.comment')}
          </Button>}

        <Button className={styles['post__bottom-button']}
        onClick={onShareClick}
        size='3xl'
        disabled={isDeleted}
        hierarchy='primary-gray'
        iconType='share'>
          {t('post.button.share')}
        </Button>
      </div>
      {currentPicture > -1 && post && <PicturesModalWindow onShareClick={onShareClick} post={post} onClose={() => setCurrentPicture(-1)} pictureState={pictureState} />}
    </>
    ) : (
      post && <EditPostComponent postToEdit={post} onSendSuccess={onEditSuccess} onCancelEdit={onCancelEdit}/>
        )}
    </div>
  </>
});

import React, { useState, forwardRef, useRef, useEffect, FormEvent, FocusEvent } from "react";
import styles from "./input.module.css";
import { useController } from "react-hook-form";
import { formatPhoneValue } from "@ui-kit/helpers/format-phone-value";
import { Menu } from "../drop-down/comp/dropdown-menu/menu.comp";
import { phoneCodesToMenuItems } from "@ui-kit/helpers/phone-codes-to-menu-items";
import COUNTRY_PHONE from "@ui-kit/data/countrie-phone";
import { MenuItem } from "../drop-down/modele/menu-item";
import chevronDown from "@assets/images/chevron-down.svg";
import { Icon } from "@ui-kit";
import { Tooltips } from "@ui-kit/components/tooltip/tooltip.comp";
import { useTranslation } from "react-i18next";


type Props = {
  label: string,
  type?: "text"|"password"|"phone"|"number"|"email"|"gender",
  placeholder?: string,
  className?: React.HTMLAttributes<HTMLDivElement>|string,
  onChange?: (...event: any[]) => void;
  supportingText?: string;
  disabled?: boolean;
  hasErrors?: boolean;
  errorsMess?: string;
  control: any,
  name: string,
  text?: string,
  value?: string,
  tips?: string;
  isSaved?: boolean;
  isSetting?: boolean;
  isDate?: boolean;
  onConfirm?: () => void;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
}

const DIGITS = '0123456789'.split('');

export const InputFormComponent = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const {label, name, control, type, placeholder, className, supportingText, disabled, hasErrors, errorsMess, tips, isSaved, isSetting, onConfirm, onFocus, onBlur, isDate } = props;

  const { t } = useTranslation('common');

  const genderMenu=[
    { label: t('genderInputField.man'), value: t('genderInputField.man') },
    { label: t('genderInputField.woman'), value: t('genderInputField.woman') },
    { label: '', value: '' },
  ];

  const [formatedValue, setFormatedValue] = useState('');
  const [dropdownAnimation, setDropdownAnimation] = useState(false);
  const [cursor, setCursor] = useState(0);
  const [visiblePassword, setvisiblePassword] = useState(false);
  const [countryCode, setCountryCode] = useState('');
  const [showDropdown, setShowDropdown] = useState(false);
  const { field } = useController({ name, control });

  const [text, setText] = useState(field.value || '');

  const inputRef = useRef<HTMLInputElement>(null);

  const phoneInput = (str: string) =>
    str ? `+${str.split('').filter((ch) => DIGITS.includes(ch)).join('')}` : '';

  const handleGenderChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setText(e.target.value);
    field.onChange(e.target.value);
    };

  useEffect(() => {
    if (type !== 'phone') {
      return setFormatedValue(text);
    }

    const data = formatPhoneValue(text ?? '');
    setFormatedValue(data.phone);
    setCountryCode(data.country);
    setCursor(data.position);

    if (phoneInput(text).length > phoneInput(data.phone).length) {
      setText(`${text.substring(0, phoneInput(data.phone).length)}`);
    }
  }, [text])

  const onfocus = (e: FocusEvent<HTMLInputElement, Element>) => {
    e.target.setSelectionRange(cursor, cursor);
  }

  useEffect(() => {
    inputRef.current?.setSelectionRange(cursor, cursor);
  }, [cursor]);

  useEffect(() => {
  }, [field.value]);

  const numberInput = (str: string) =>
    str.split('').filter((ch) => DIGITS.includes(ch)).join('');

  const _onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    switch(type) {
      case 'phone':
        setText(
          phoneInput(e.target.value)
        );
        return;
      case 'number':
        setText(
          numberInput(e.target.value)
        );
        break;
      default:
        const newValue = e.target.value;
        setText(newValue);
        field.onChange(newValue);
    }
  }

  const onSelect = (item: MenuItem) => {
    if (!Number.isNaN(+item.key) && type === 'phone') {
      const index = +item.key;
      const foundCoutryPhone = COUNTRY_PHONE[index];
      if (foundCoutryPhone.code === countryCode) {
        return;
      }
      const phone =
        foundCoutryPhone.phone + ((foundCoutryPhone?.next?.length === 1) ? foundCoutryPhone?.next?.[0] ?? '' : '');
      setText(phoneInput(phone));
    }
  }

  useEffect(() => {
    field.onChange({ target: { value: text } });
  }, [text]);

  const _onInput = (e: FormEvent<HTMLInputElement>) => {
    if (type !== 'phone') {
      return;
    }
    const key = (e.nativeEvent as InputEvent)?.data ?? '-1';
    if (type === 'phone') {
      if ((e.nativeEvent as InputEvent)?.inputType === 'deleteContentBackward') {
        setText(
          phoneInput(text.substring(0, text.length - 1))
        );
      } else if (!Number.isNaN(key) && +key >= 0 && +key <= 9) {
        setText(
          phoneInput(`${text}${key}`)
        );
      } else {
        setText(phoneInput(text));
      }
    }
  }

  const openMenu = () => {
    setShowDropdown(true);
  }

  useEffect(() => {
    if (showDropdown) {
      setTimeout(() => {
        document.addEventListener('click', () => { setShowDropdown(false); }, { once: true })
      }, 0);
    }
    setDropdownAnimation(showDropdown);
  }, [showDropdown])

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement> ) => {
    const input = e.target.value.replace(/\D/g, '');
    const day = input.slice(0, 2);
    const month = input.slice(2, 4);
    const year = input.slice(4, 8);

    let newDate = '';
    if (day.length) {
        newDate = day;
    }
    if (month.length) {
        newDate += `/${month}`;
    }
    if (year.length) {
        newDate += `/${year}`;
    }

    setText(newDate);
    field.onChange(newDate);
  };

  useEffect(() => {
    if (name === "dateOfBirth") {
      setText(field.value || "");
    }
  }, [field.value, name]);

  return (
    <div className={`${styles.inputBlock} ${className ? className : ''}`}>
      <h4 className={styles.phoneTitle}>{label}</h4>
      {type === 'phone' && <div className={styles.countryCode} onClick={openMenu}>
        {countryCode}
        <img src={chevronDown} alt='chevron-down'></img>
      </div>}

      {type === 'gender' ? (
        <div className={`${styles['gender-container']}`}>
        <select
          {...field}
          value={text}
          onChange={handleGenderChange}
          className={`${styles.input} ${styles['input_select']}`}
          disabled={disabled}
        >
          {genderMenu.map(option => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        </div>
      ) : (
      <input
        {...field}
        value={formatedValue}
        onChange={isDate ? handleDateChange : _onChange}
        onInput={_onInput}
        ref={inputRef}
        onFocus={onfocus}
        className={`
          ${styles.input}
          ${type === 'phone' ? styles.input_phone : ''}
          ${hasErrors ? styles.input_error : ''}
          ${label === 'Пароль' ? styles.input_password : ''}`}
          placeholder={placeholder}
        disabled={disabled}
        type={type === 'phone' ? 'tel' : type === 'password' ? !visiblePassword ? 'password' : 'text' : type}
      />
      )}

      {!!errorsMess && <span className={styles['input_error-text']}>{errorsMess}</span>}

      {type === 'password' &&
        <div className={`${styles['button-pass-eye']}`}>
        <button type='button' onClick ={()=> setvisiblePassword(!visiblePassword)}>
          {!!visiblePassword ? <Icon icon='eye-off-gray' size='xxs' /> : <Icon icon='eye-gray' size='xxs' /> }
        </button>
        </div>
      }

      {showDropdown &&
        <Menu
          items={phoneCodesToMenuItems(COUNTRY_PHONE, countryCode)}
          onClick={onSelect}
          className={`${styles.input__dropdown} ${dropdownAnimation ? styles.input__dropdown_show : ''}`} />
      }

      {type === 'text' && supportingText && tips &&
      <Tooltips isIcon={true} tips={tips}/>
      }

      {supportingText && <span className={`${styles['inputBlock_supporting-text']}`}>{supportingText}</span>}

      {isSetting && !isSaved && <div className={`${styles['button-setting-confirm']}`}>
        <button type='button' onClick={onConfirm}>
        {t('inputField.buttonConfirm')}
        </button>
        </div>}

      {isDate &&
        <div className={`${styles['calendar']}`}>
        <button onClick={onConfirm}>
          <Icon icon='calendar' size='xxs-plus' color='#367DFF'/>
        </button>
        </div>
      }

      {isSaved && <Icon className={styles['saved-icon']} icon='check-circle' color='#039855' size='xxs' />}
    </div>
  )
});
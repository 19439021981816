import { InputFormComponent} from "@ui-kit";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

interface PasswordProps {
  control: any;
  errors: any;
  validationRules: any;
  touchedFields: any;
}

export const PasswordComponent = ({ control, errors, validationRules, touchedFields }: PasswordProps) => {
  const { t } = useTranslation('authorization');

  return (
    <>
      <Controller
        control={control}
        name='password'
        rules={validationRules.password}
        render={({ field: { onChange, onBlur, value } }) => (
        <InputFormComponent
          label={t('input.password.label')}
          placeholder={t('input.password.placeholder')}
          type='password'
          name='password'
          value={value}
          control={control}
          onChange={onChange}
          onBlur={onBlur}
          supportingText={t('input.password.supportText')}
          hasErrors={!!errors.password && touchedFields.password}
          errorsMess={errors.password?.message}
        />
        )}
      />
      <Controller
        control={control}
        name='passwordRepeat'
        rules={validationRules.passwordRepeat}
        render={({ field: { onChange, onBlur, value } }) => (
        <InputFormComponent
          label={t('input.repeatPassword.label')}
          placeholder={t('input.repeatPassword.placeholder')}
          type='password'
          name='passwordRepeat'
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          control={control}
          hasErrors={!!errors.passwordRepeat && touchedFields.passwordRepeat}
          errorsMess={errors.passwordRepeat?.message}
        />
        )}
      />
    </>
  )};
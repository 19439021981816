import styles from "./user-agreement.module.css";
import { useTranslation, Trans } from "react-i18next";

type Props = {
  isModal?: boolean;
}

export const UserAgreementComponent = ({isModal}: Props) => {
  const { t } = useTranslation('agreement');

  return (
      <div className={`${styles['user-agreement_container']} ${isModal ? styles['modal_scroll']: ""}`}>
        <section className={styles['agreement_section']}>
          <p className={styles['agreement_details']}>{t('main.title.date')}</p>
          <p className={styles['agreement_details']}>
            <Trans
              i18nKey="main.title.text"
              ns="agreement"
                components={{
                  1: <a className={styles['link']} href="https://ecitty.com" target="_blank" rel="noopener noreferrer" />,
              }}
            />
          </p>
          <p className={styles['agreement_details']}>
            <Trans
              i18nKey="main.notice"
              ns="agreement"
                components={{
                  1: <a className={styles['link']} href="https://ecitty.com/terms" target="_blank" rel="noopener noreferrer" />,
                  2: <a className={styles['link']} href="https://ecitty.com/privacy-policy" target="_blank" rel="noopener noreferrer" />,
              }}
            />
          </p>
          <h6 className={styles['section_header']}>{t('sections.section1.header')}</h6>
          <p className={styles['agreement_details']}>{t('sections.section1.subSection1.number')}{t('sections.section1.subSection1.text')}</p>
          <p className={styles['agreement_details']}><strong>{t('sections.section1.definition1.term')}</strong> — {t('sections.section1.definition1.definition')}</p>
          <p className={styles['agreement_details']}><strong>{t('sections.section1.definition2.term')}</strong> —
            <Trans
              i18nKey="sections.section1.definition2.definition"
              ns="agreement"
                components={{
                  1: <a className={styles['link']} href="https://ecitty.com/help" target="_blank" rel="noopener noreferrer" />
              }}
            />
          </p>
          <p className={styles['agreement_details']}><strong>{t('sections.section1.definition3.term')}</strong> — {t('sections.section1.definition3.definition')}</p>
          <p className={styles['agreement_details']}><strong>{t('sections.section1.definition4.term')}</strong> — {t('sections.section1.definition4.definition')}</p>
          <p className={styles['agreement_details']}><strong>{t('sections.section1.definition5.term')}</strong> — {t('sections.section1.definition5.definition')}</p>
          <p className={styles['agreement_details']}><strong>{t('sections.section1.definition6.term')}</strong> — {t('sections.section1.definition6.definition')}</p>
          <p className={styles['agreement_details']}><strong>{t('sections.section1.definition7.term')}</strong> — {t('sections.section1.definition7.definition')}</p>
          <p className={styles['agreement_details']}><strong>{t('sections.section1.definition8.term')}</strong> — {t('sections.section1.definition8.definition')}</p>
          <p className={styles['agreement_details']}><strong>{t('sections.section1.definition9.term')}</strong> — {t('sections.section1.definition9.definition')}</p>
          <p className={styles['agreement_details']}>{t('sections.section1.subSection2.number')}{t('sections.section1.subSection2.text')}</p>
          <p className={styles['agreement_details']}>{t('sections.section1.subSection3.number')}
            <Trans
              i18nKey="sections.section1.subSection3.text"
              ns="agreement"
                components={{
                  1: <a className={styles['link']} href="https://ecitty.com/terms" target="_blank" rel="noopener noreferrer" />,
                  2: <a className={styles['link']} href="https://ecitty.com/privacy-policy" target="_blank" rel="noopener noreferrer" />,
              }}
            />
          </p>
        </section>

        <section className={styles['agreement_section']}>
          <h6 className={styles['section_header']}>{t('sections.section2.header')}</h6>
          <p className={styles['agreement_details']}>{t('sections.section2.subSection1.number')}{t('sections.section2.subSection1.text')}</p>
          <p className={styles['agreement_details']}>{t('sections.section2.subSection2.number')}{t('sections.section2.subSection2.text')}</p>
          <p className={styles['agreement_details']}>{t('sections.section2.subSection3.number')}{t('sections.section2.subSection3.text')}</p>
          <ul className={styles['details_list']}>
            <li className={styles['details-list_item']}>{t('sections.section2.subSection3.list.item1.number')}{t('sections.section2.subSection3.list.item1.text')}</li>
            <li className={styles['details-list_item']}>{t('sections.section2.subSection3.list.item2.number')}{t('sections.section2.subSection3.list.item2.text')}</li>
            <li className={styles['details-list_item']}>{t('sections.section2.subSection3.list.item3.number')}{t('sections.section2.subSection3.list.item3.text')}</li>
            <li className={styles['details-list_item']}>{t('sections.section2.subSection3.list.item4.number')}{t('sections.section2.subSection3.list.item4.text')}</li>
            <li className={styles['details-list_item']}>{t('sections.section2.subSection3.list.item5.number')}{t('sections.section2.subSection3.list.item5.text')}</li>
          </ul>
          <p className={styles['agreement_details']}>{t('sections.section2.subSection4.number')}{t('sections.section2.subSection4.text')}</p>
          <ul className={styles['details_list']}>
            <li className={styles['details-list_item']}>{t('sections.section2.subSection4.list.item1.number')}{t('sections.section2.subSection4.list.item1.text')}</li>
            <li className={styles['details-list_item']}>{t('sections.section2.subSection4.list.item2.number')}{t('sections.section2.subSection4.list.item2.text')}</li>
          </ul>
          <p className={styles['agreement_details']}>{t('sections.section2.subSection5.number')}{t('sections.section2.subSection5.text')}</p>
          <p className={styles['agreement_details']}>{t('sections.section2.subSection6.number')}{t('sections.section2.subSection6.text')}</p>
          <p className={styles['agreement_details']}>{t('sections.section2.subSection7.number')}{t('sections.section2.subSection7.text')}</p>
          <p className={styles['agreement_details']}><strong>{t('sections.section2.subSection8.number')}{t('sections.section2.subSection8.text')}</strong></p>
        </section>

        <section className={styles['agreement_section']}>
          <h6 className={styles['section_header']}>{t('sections.section3.header')}</h6>
          <p className={styles['agreement_details']}>{t('sections.section3.subSection1.number')}{t('sections.section3.subSection1.text')}</p>
          <p className={styles['agreement_details']}>{t('sections.section3.subSection2.number')}{t('sections.section3.subSection2.text')}</p>
          <p className={styles['agreement_details']}>{t('sections.section3.subSection3.number')}{t('sections.section3.subSection3.text')}</p>
          <p className={styles['agreement_details']}>{t('sections.section3.subSection4.number')}{t('sections.section3.subSection4.text')}</p>
          <p className={styles['agreement_details']}>{t('sections.section3.subSection5.number')}{t('sections.section3.subSection5.text')}</p>
          <p className={styles['agreement_details']}>{t('sections.section3.subSection6.number')}{t('sections.section3.subSection6.text')}</p>
          <p className={styles['agreement_details']}>{t('sections.section3.subSection7.number')}{t('sections.section3.subSection7.text')}</p>
          <p className={styles['agreement_details']}>{t('sections.section3.subSection8.number')}{t('sections.section3.subSection8.text')}</p>
          <p className={styles['agreement_details']}>{t('sections.section3.subSection9.number')}
            <Trans
              i18nKey="sections.section3.subSection9.text"
              ns="agreement"
                components={{
                  1: <a className={styles['link']} href="https://ecitty.com/profile/delete-account" target="_blank" rel="noopener noreferrer" />,
              }}
            />
          </p>
        </section>

        <section className={styles['agreement_section']}>
          <h6 className={styles['section_header']}>{t('sections.section4.header')}</h6>
          <p className={styles['agreement_details']}>{t('sections.section4.subSection1.number')}{t('sections.section4.subSection1.text')}</p>
          <p className={styles['agreement_details']}>{t('sections.section4.subSection2.number')}{t('sections.section4.subSection2.text')}</p>
          <p className={styles['agreement_details']}>{t('sections.section4.subSection3.number')}{t('sections.section4.subSection3.text')}</p>
          <p className={styles['agreement_details']}>{t('sections.section4.subSection4.number')}{t('sections.section4.subSection4.text')}</p>
          <p className={styles['agreement_details']}>{t('sections.section4.subSection5.number')}{t('sections.section4.subSection5.text')}</p>
          <p className={styles['agreement_details']}>{t('sections.section4.subSection6.number')}{t('sections.section4.subSection6.text')}</p>
          <p className={styles['agreement_details']}>{t('sections.section4.subSection7.number')}{t('sections.section4.subSection7.text')}</p>
        </section>

        <section className={styles['agreement_section']}>
          <h6 className={styles['section_header']}>{t('sections.section5.header')}</h6>
          <p className={styles['agreement_details']}>{t('sections.section5.subSection1.number')}{t('sections.section5.subSection1.text')}</p>
          <p className={styles['agreement_details']}>{t('sections.section5.subSection2.number')}{t('sections.section5.subSection2.text')}</p>
          <p className={styles['agreement_details']}>{t('sections.section5.subSection3.number')}{t('sections.section5.subSection3.text')}</p>
          <p className={styles['agreement_details']}>{t('sections.section5.subSection4.number')}{t('sections.section5.subSection4.text')}</p>
          <p className={styles['agreement_details']}>{t('sections.section5.subSection5.number')}{t('sections.section5.subSection5.text')}</p>
          <p className={styles['agreement_details']}>{t('sections.section5.subSection6.number')}{t('sections.section5.subSection6.text')}</p>
          <p className={styles['agreement_details']}>{t('sections.section5.subSection7.number')}{t('sections.section5.subSection7.text')}</p>
          <p className={styles['agreement_details']}>{t('sections.section5.subSection8.number')}{t('sections.section5.subSection8.text')}</p>
          <p className={styles['agreement_details']}>{t('sections.section5.subSection9.number')}{t('sections.section5.subSection9.text')}</p>
          <p className={styles['agreement_details']}>{t('sections.section5.subSection10.number')}{t('sections.section5.subSection10.text')}</p>
          <p className={styles['agreement_details']}>{t('sections.section5.subSection11.number')}{t('sections.section5.subSection11.text')}</p>
        </section>

        <section className={styles['agreement_section']}>
          <h6 className={styles['section_header']}>{t('sections.section6.header')}</h6>
          <p className={styles['agreement_details']}>{t('sections.section6.subSection1.number')}{t('sections.section6.subSection1.text')}</p>
          <p className={styles['agreement_details']}>{t('sections.section6.subSection2.number')}{t('sections.section6.subSection2.text')}</p>
          <p className={styles['agreement_details']}>{t('sections.section6.subSection3.number')}{t('sections.section6.subSection3.text')}</p>
          <ul className={styles['details_list']}>
            <li className={styles['details-list_item']}>{t('sections.section6.subSection3.list.item1.number')}{t('sections.section6.subSection3.list.item1.text')}</li>
            <li className={styles['details-list_item']}>{t('sections.section6.subSection3.list.item2.number')}{t('sections.section6.subSection3.list.item2.text')}</li>
            <li className={styles['details-list_item']}>{t('sections.section6.subSection3.list.item3.number')}{t('sections.section6.subSection3.list.item3.text')}</li>
            <li className={styles['details-list_item']}>{t('sections.section6.subSection3.list.item4.number')}{t('sections.section6.subSection3.list.item4.text')}</li>
            <li className={styles['details-list_item']}>{t('sections.section6.subSection3.list.item5.number')}{t('sections.section6.subSection3.list.item5.text')}</li>
          </ul>
          <p className={styles['agreement_details']}>{t('sections.section6.subSection4.number')}{t('sections.section6.subSection4.text')}</p>
          <p className={styles['agreement_details']}>{t('sections.section6.subSection5.number')}{t('sections.section6.subSection5.text')}</p>
          <p className={styles['agreement_details']}>{t('sections.section6.subSection6.number')}{t('sections.section6.subSection6.text')}</p>
          <p className={styles['agreement_details']}>{t('sections.section6.subSection7.number')}{t('sections.section6.subSection7.text')}</p>
          <p className={styles['agreement_details']}>{t('sections.section6.subSection8.number')}{t('sections.section6.subSection8.text')}</p>
          <p className={styles['agreement_details']}>{t('sections.section6.subSection9.number')}{t('sections.section6.subSection9.text')}</p>
        </section>

        <section className={styles['agreement_section']}>
          <h6 className={styles['section_header']}>{t('sections.section7.header')}</h6>
          <p className={styles['agreement_details']}>{t('sections.section7.subSection1.number')}{t('sections.section4.subSection1.text')}</p>
          <p className={styles['agreement_details']}>{t('sections.section7.subSection2.number')}{t('sections.section4.subSection2.text')}</p>
          <p className={styles['agreement_details']}>{t('sections.section7.subSection3.number')}{t('sections.section4.subSection3.text')}</p>
        </section>

        <section className={styles['agreement_section']}>
          <h6 className={styles['section_header']}>{t('sections.section8.header')}</h6>
          <p className={styles['agreement_details']}>{t('sections.section8.subSection1.number')}{t('sections.section8.subSection1.text')}</p>
          <ul className={styles['details_list']}>
            <li className={styles['details-list_item']}>{t('sections.section8.subSection1.list.item1.number')}{t('sections.section8.subSection1.list.item1.text')}</li>
            <li className={styles['details-list_item']}>{t('sections.section8.subSection1.list.item2.number')}{t('sections.section8.subSection1.list.item2.text')}</li>
            <li className={styles['details-list_item']}>{t('sections.section8.subSection1.list.item3.number')}{t('sections.section8.subSection1.list.item3.text')}</li>
            <li className={styles['details-list_item']}>{t('sections.section8.subSection1.list.item4.number')}{t('sections.section8.subSection1.list.item4.text')}</li>
          </ul>
          <p className={styles['agreement_details']}>{t('sections.section8.subSection2.number')}{t('sections.section8.subSection2.text')}</p>
        </section>

        <section className={styles['agreement_section']}>
          <h6 className={styles['section_header']}>{t('sections.section9.header')}</h6>
          <p className={styles['agreement_details']}>{t('sections.section9.subSection1.number')}
            <Trans
              i18nKey="sections.section9.subSection1.text"
              ns="agreement"
              components={{
                1: <a className={styles['link']} href="https://ecitty.com/privacy-policy" target="_blank" rel="noopener noreferrer" />,
              }}
            />
          </p>
          <p className={styles['agreement_details']}>{t('sections.section9.subSection2.number')}{t('sections.section9.subSection2.text')}</p>
          <p className={styles['agreement_details']}>{t('sections.section9.subSection3.number')}{t('sections.section9.subSection3.text')}</p>
          <p className={styles['agreement_details']}>{t('sections.section9.subSection4.number')}{t('sections.section9.subSection4.text')}</p>
          <ul className={styles['details_list']}>
            <li className={styles['details-list_item_dot']}>{t('sections.section9.subSection4.list.item1.text')}</li>
            <li className={styles['details-list_item_dot']}>{t('sections.section9.subSection4.list.item2.text')}</li>
          </ul>
          <p className={styles['agreement_details']}>{t('sections.section9.subSection5.number')}{t('sections.section9.subSection5.text')}</p>
          <ul className={styles['details_list']}>
            <li className={styles['details-list_item_dot']}>{t('sections.section9.subSection5.list.item1.text')}</li>
            <li className={styles['details-list_item_dot']}>{t('sections.section9.subSection5.list.item2.text')}</li>
            <li className={styles['details-list_item_dot']}>{t('sections.section9.subSection5.list.item3.text')}</li>
            <li className={styles['details-list_item_dot']}>{t('sections.section9.subSection5.list.item4.text')}</li>
          </ul>
          <p className={styles['agreement_details']}>{t('sections.section9.subSection6.number')}
            <Trans
              i18nKey="sections.section9.subSection6.text"
              ns="agreement"
            />
          </p>
        </section>

        <section className={styles['agreement_section']}>
          <h6 className={styles['section_header']}>{t('sections.section10.header')}</h6>
          <p className={styles['agreement_details']}>{t('sections.section10.subSection1.number')}{t('sections.section10.subSection1.text')}</p>
          <p className={styles['agreement_details']}>{t('sections.section10.subSection2.number')}{t('sections.section10.subSection2.text')}</p>
          <p className={styles['agreement_details']}>{t('sections.section10.subSection3.number')}{t('sections.section10.subSection3.text')}</p>
        </section>

        <section className={styles['agreement_section']}>
          <h6 className={styles['section_header']}>{t('sections.section11.header')}</h6>
          <p className={styles['agreement_details']}>{t('sections.section11.details.email.title')}{t('sections.section11.details.email.text')}</p>
          <p className={styles['agreement_details']}>{t('sections.section11.details.phone.title')}{t('sections.section11.details.phone.text')}</p>
          <p className={styles['agreement_details']}>{t('sections.section11.details.adress.title')}{t('sections.section11.details.adress.text')}</p>
        </section>

        <section className={styles['agreement_section']}>
          <h6 className={styles['section_header']}>{t('sections.section12.header')}</h6>
          <p className={styles['agreement_details']}>{t('sections.section12.subSection1.number')}
            <Trans
              i18nKey="sections.section12.subSection1.text"
              ns="agreement"
              components={{
                1: <a className={styles['link']} href="https://ecitty.com/profile/delete-account" target="_blank" rel="noopener noreferrer" />,
              }}
            />
          </p>
          <p className={styles['agreement_details']}>{t('sections.section12.subSection2.number')}
            <Trans
              i18nKey="sections.section12.subSection2.text"
              ns="agreement"
            />
          </p>
          <p className={styles['agreement_details']}>{t('sections.section12.subSection3.number')}
            <Trans
              i18nKey="sections.section12.subSection3.text"
              ns="agreement"
            />
          </p>
        </section>
        <section className={styles['agreement_section']}>
          <h6 className={styles['section_header']}>{t('sections.section13.header')}</h6>
          <p className={styles['agreement_details']}>
            <Trans
              i18nKey="sections.section13.subSection1.text"
              ns="agreement"
              components={{
                1: <a className={styles['link']} href="https://ecitty.com/terms" target="_blank" rel="noopener noreferrer" />,
                2: <a className={styles['link']} href="https://ecitty.com/privacy-policy" target="_blank" rel="noopener noreferrer" />,
              }}
            />
          </p>
          <p className={styles['agreement_details']}>
            <Trans
              i18nKey="sections.section13.subSection2.text"
              ns="agreement"
              components={{
                1: <a className={styles['link']} href="https://ecitty.com" target="_blank" rel="noopener noreferrer" />,
              }}
            />
          </p>
        </section>
     </div>
  );
}
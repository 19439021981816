import styles from "./registration.module.css";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { toastsStore, modalCustomDialogsStore, registrationStore, userStore } from "@store";
import { AuthWindow, Button, InputFormComponent, LinkComponent, TabItem, Tabs } from "@ui-kit";
import { GoogleButton, FacebookButton, AppleButton } from "@ui-kit/components/button/social-buttons/";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { useValidationRules } from "@ui-kit/components/input/helpers/useValidationRules";
import { City, CreateCityInput, CreateUserInput } from "@graphql/graphql";
import { RegistrationMap } from "../../components/registration-map/registration-map.comp";
import { onGoogleClick } from "@shared/helpers/on-google-click";
import { UserAgreementComponent } from "../../../agreement-page/component/user-agreement.comp";
import { OgMetaData } from "@shared/components/og-meta-data/og-meta-data.comp";
import { useTranslation, Trans } from "react-i18next";
import { PasswordComponent } from "src/auth-pages/components/password/password.comp";
import { handleSendCode } from "../../helpers/send.code";

export const SES_STOR_REG_CITY = 'regCityPlaceId';
const ENABLED_OAUTH = process.env.REACT_APP_DISABLE_OAUTH?.toLowerCase() !== 'true';
export interface NewUser {
  firstname: string,
  lastname: string,
  username: string,
  phone: string;
  email: string,
  password: string,
  passwordRepeat: string,
}

const RegistrationPage = observer(() => {
  const { t } = useTranslation('authorization');

  const tabs = [
    { key: 'phone', title: t('tabs.phone') },
    { key: 'email', title: t('tabs.email') },
  ];

  const [city, setCity] = useState<City|null>(null);
  const [curentTab, setCurrentTab] = useState<TabItem>(tabs[0]);
  const [flowStep, setFlowStep] = useState(1);

  const { handleSubmit, control, formState: { errors, isValid, touchedFields } } = useForm<NewUser>({
    mode: 'onBlur',
    defaultValues:  registrationStore.getData() as NewUser,
  });

  const validationRules = useValidationRules();
  const { showDialog } = modalCustomDialogsStore;
  const navigate = useNavigate();

  useEffect(() => {
    if (city?.place_id) {
      sessionStorage.setItem(SES_STOR_REG_CITY, city?.place_id);

      const newCity: CreateCityInput = {
        name: city.name ?? '',
        country: city.country ?? '',
        longName: city.longName ?? '',
        picture: city.picture ? { url: city.picture.url ?? '' } : undefined,
        place_id: city.place_id
      }
      registrationStore.setCity(newCity);
    } else {
      sessionStorage.removeItem(SES_STOR_REG_CITY);
      registrationStore.setCity(undefined);
    }
  }, [city]);


  const formSubmitHandler: SubmitHandler<NewUser> = async (data: NewUser) => {
    if (data.password !== data.passwordRepeat) {
      toastsStore.addErrorToast(t('toasts.error.password'));
      return;
    }

    registrationStore.setAllData(data);

    try {
      await handleSendCode();
      navigate('/registration/verify');
    } catch (error) {
      console.error('Ошибка при отправке кода:', error);
    }
  }

  const showUserAgreement = () => {
    modalCustomDialogsStore.hideDialog();
    return showDialog(
      <UserAgreementComponent isModal={true}/>,
      {
        title: t('modal.titleAgree'),
      }
    );
  }

  const handleTabChange = (key: string) => {
    const newTab = tabs.find((tab) => tab.key === key) ?? tabs[0];
    setCurrentTab(newTab);
  };

  return (
    <>
      <OgMetaData type='main' data={t('register.header')} />
      {!city && <AuthWindow pageType='registration-map'><RegistrationMap selectCity={setCity}/></AuthWindow>}
      {city &&
        <AuthWindow pageType='registration'>
          <h2 className={styles.title}>{t('register.title')}</h2>
          <h3 className={styles.subtitle}>
            {flowStep === 1 ? t('register.subtitle') : t('register.passwordTitle')}
          </h3>

          {flowStep === 1 && <Tabs
            type="auth"
            items={tabs}
            current={curentTab}
            onClick={handleTabChange}
          />}

          {flowStep === 1 &&
            <form className={styles['form-registr']} onSubmit={handleSubmit(() => setFlowStep(2))}>
              <div className={styles.inputBlock}>
                {curentTab.key === 'phone' ?
                <Controller
                  control={control}
                  name='phone'
                  rules={validationRules.phone}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <InputFormComponent
                      label={t('input.phone.label')}
                      placeholder={t('input.phone.placeholder')}
                      type='phone'
                      name='phone'
                      value={value}
                      control={control}
                      onChange={onChange}
                      onBlur={onBlur}
                      hasErrors={!!errors.phone}
                      errorsMess={errors.phone?.message}
                    />
                  )}
                /> :
                <Controller control={control}
                  name='email'
                  rules={validationRules.email}
                  render={({ field: { onChange, onBlur, value } }) => (
                <InputFormComponent
                  label={t('input.email.label')}
                  placeholder={t('input.email.placeholder')}
                  type='text'
                  name='email'
                  value={value}
                  control={control}
                  onChange={onChange}
                  onBlur={onBlur}
                  hasErrors={!!errors.email}
                  errorsMess={errors.email?.message}
                />
                )}
                />}
                <Controller
                  control={control}
                  name='username'
                  rules={validationRules.username}
                  render={({ field: { onChange, onBlur, value } }) => (
                  <InputFormComponent
                    label={t('input.username.label')}
                    placeholder={t('input.username.placeholder')}
                    type='text'
                    name='username'
                    value={value}
                    control={control}
                    onChange={onChange}
                    onBlur={onBlur}
                    supportingText={t('input.username.supportText')}
                    hasErrors={!!errors.username}
                    errorsMess={errors.username?.message}
                    tips={t('input.username.tips')}
                  />
                  )}
                />
              </div>
              <div className={styles.usernameBlock}>
                <Controller
                  control={control}
                  name='firstname'
                  rules={validationRules.firstname}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <InputFormComponent
                      label={t('input.firstname.label')}
                      placeholder={t('input.firstname.placeholder')}
                      type='text'
                      name='firstname'
                      value={value}
                      control={control}
                      onChange={onChange}
                      onBlur={onBlur}
                      hasErrors={!!errors.firstname}
                      errorsMess={errors.firstname?.message}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name='lastname'
                  rules={validationRules.lastname}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <InputFormComponent
                      label={t('input.lastname.label')}
                      placeholder={t('input.lastname.placeholder')}
                      type='text'
                      name='lastname'
                      value={value}
                      control={control}
                      onChange={onChange}
                      onBlur={onBlur}
                      hasErrors={!!errors.lastname}
                      errorsMess={errors.lastname?.message}
                    />
                  )}
                />
              </div>
              <Button size='xl' disabled={!isValid} type='submit'>{t('register.button.continue')}</Button>
            </form>
          }

          {flowStep === 2 &&
            <form className={styles['form-registr']} onSubmit={handleSubmit(formSubmitHandler)}>
              <PasswordComponent control={control} validationRules={validationRules} errors={errors} touchedFields={touchedFields}/>
              <div className={styles.buttonConfirmBlock}>
                <Button size='xl' onClick={() => setFlowStep(1)} hierarchy='quaternary-color' iconType='chevron-left-color'>{t('confirm.button.back')}</Button>
                <Button size='xl' type='submit' disabled={!isValid}>{t('register.button.continue')}</Button>
              </div>
          </form>
          }

          <p className={styles.userAgreementBlock}>
            <Trans
              i18nKey="link.agreement"
              ns="authorization"
                components={{
                  1: <LinkComponent typeLink='gray-underline' click={showUserAgreement} />
                }}
            />
            {ENABLED_OAUTH && flowStep === 1 && <div className={styles.socialButtonsBlock}>
              <GoogleButton btnSmall={true} supportingText={false} onClick={onGoogleClick}></GoogleButton>
              <FacebookButton btnSmall={true} supportingText={false}></FacebookButton>
              <AppleButton btnSmall={true} supportingText={false}></AppleButton>
            </div>}
          </p>

          {flowStep === 1 && <p className={styles.bottomWindowBlock}>
            <span>{t('link.haveAccount')}</span>
            <LinkComponent link='/login'>{t('register.button.enter')}</LinkComponent>
          </p>}

      </AuthWindow>}
    </>
  );
});

export default RegistrationPage;
import { makeAutoObservable } from "mobx";
import { toastsStore } from "./toasts.store";
import { NavigateFunction } from "react-router-dom";
import { BlockChatUser, Chat, CreateChatInput, CreateMessageInput, Message, MessageConnection } from "@graphql/graphql";
import { apolloClient, SESSION_STORAGE_JWT_TOKEN } from "src/apollo-client";
import { listMessages, listUserChats } from "@graphql/docs/queries";
import { onCreateChat, onCreateMessage, onTyping, onUpdateMessage } from "@graphql/docs/subscriptions";
import { blockUser, createChat, createMessage, deleteChat, deliveredMessage, deliveredMessageList, makeReaction, readMessage, readMessageList, startTyping, stopTyping, switchOffNotifications, switchOnNotifications, unblockUser } from "@graphql/docs/mutations";
import { userStore } from "./user.store";
import { v4 as uuid } from "uuid";
import { messageNotificationsStore } from "./mesage-notifications.store";
import { getI18n } from "react-i18next";
import { showNotification } from "@shared/helpers/notification";


const isChat = (chat: Chat | null) : chat is Chat => chat !== null;

const isMessage = (message: Message | null) : message is Message => message !== null

const usernameFilter = (chat: Chat, username?: string): boolean =>
  chat.usernames?.includes(username ?? '') === true;

type MessageStore = {
  [chatId: string]: Message[]
}

class MessagesStore {
  chats: Chat[] = [];
  nextToken: { [chatId: string]: string | null } = {};
  messages: MessageStore = {};
  messagesCout: number = 0;
  username?: string;
  subsCreated = false;
  chatsLoaded = false;
  loading = false;
  loadingChats = false;
  loadingMessages = false;
  loadingMoreMessages = false;

  navigate?: NavigateFunction;

  constructor() {
    makeAutoObservable(this);
  }

  get t() {
    const i18n = getI18n();
    return i18n.isInitialized ? i18n.t.bind(i18n) : () => "";
  }

  createSubscriptions = (username: string, navigate: NavigateFunction) => {
    this.username = username;
    this.navigate = navigate;
    this.loadingChats = true;
    this.loadingMessages = true;
    this.getAllChats();
  }

  getAllChats = async (): Promise<Chat[]> => {
    const res = await apolloClient.query<{listUserChats: Chat[]}>({ query: listUserChats });
    const data = res.data.listUserChats ?? [];
    this.chats = data.filter(isChat).filter((chat) => usernameFilter(chat, this.username));
    this.loadingChats = false;
    this.messagesCout = this.chats.reduce((sum, chat) => sum + (chat.newMessagesCount ?? 0), 0);
    this.createAllSubscriptions();
    this.loadingMessages = false;
    return this.chats;
  }

  getChatMessages = async (chat: Chat|string, limit: number, nextToken: boolean) => {
    if (nextToken) {
      this.loadingMoreMessages = true;
    } else {
      this.loadingMessages = true;
    }
    if (!chat) {
      chat = ''
    }

    if (typeof chat !== 'string') {
      chat = chat.chatId ?? ''
    }

    if (nextToken && this.nextToken[chat ?? ''] === null) {
      return;
    }

    if (!nextToken) {
      this.nextToken[chat ?? ''] = null;
    }

    const res = await apolloClient.query<{listMessages: MessageConnection}>({
      query: listMessages,
      variables: { input: { id: chat, limit, nextToken: this.nextToken[chat ?? ''] } },
    });

    const data = res.data?.listMessages?.items ?? [];
    this.messages[chat ?? ''] = nextToken
      ? [...(this.messages[chat ?? ''] ?? []), ...data]
      : data;
    this.nextToken[chat ?? ''] = res.data?.listMessages?.nextToken ?? null;
    // const newMessages = this.messages[chat ?? '']
    //   .filter((msg) => msg.username !== this.username && !msg.read?.includes(userStore.currentUser?.username ?? '-'));
    // this.messagesCout += newMessages.length;
    const ids = this.messages[chat ?? '']
      .filter((msg) =>
        !msg.delivered?.includes(userStore.currentUser?.username ?? '-')
        && msg.username !== userStore.currentUser?.username
      )
      .map((msg) => msg.id)
      .filter(Boolean) as string[];
    this.deliveredMessageList(ids)
    this.loadingMessages = false;
  }

  private createAllSubscriptions = () => {
    if (!this.subsCreated) {
      if (!sessionStorage.getItem(SESSION_STORAGE_JWT_TOKEN)) {
        return;
      }
      this.subsCreated = true;
      this.chatsLoaded = true;
      this.createChatsStreams();
      this.createMessageStreams();
      this.createMessageUpdateStream();
      this.createTypingChatStream();
    }
  }

  private createChatsStreams = () => {
    // const token = sessionStorage.getItem(SESSION_STORAGE_JWT_TOKEN);
    apolloClient.subscribe<{onCreateChat: Chat}>({
      query: onCreateChat,
      // context: { headers: { Authorization: token ? `Bearer ${token}` : undefined } },
    })
      .filter((value) =>
        !!value.data?.onCreateChat &&
        !!value.data.onCreateChat.usernames?.find((uname) => uname === this.username)
      )
      .map((value) => value.data?.onCreateChat)
      .filter((value) =>
        !!value && isChat(value)
      ).subscribe({
        next: (newChat) => {
          if (!newChat?.chatId) {
            return;
          }
          this.chats = [newChat, ...this.chats];
          this.messages[newChat?.chatId] = []
        },
        error: (error) => console.error(error),
      });
  }

  private createMessageStreams = () => {
    // const token = sessionStorage.getItem(SESSION_STORAGE_JWT_TOKEN);
    apolloClient.subscribe<{onCreateMessage: Message}>({
      query: onCreateMessage,
      // context: { headers: { Authorization: token ? `Bearer ${token}` : undefined } },
    }).subscribe({
      next: (value) => {
        const newMsg = value.data?.onCreateMessage;
        const chatId = newMsg?.chatId;
        if (!chatId) {
          return;
        }
        this.messages[chatId] = this.messages[chatId] ? [newMsg, ...this.messages[chatId]] : [newMsg];
        const foundChatIndex = this.chats.findIndex((chat) => chat.chatId === newMsg.chatId);
        if (foundChatIndex >= 0) {
          this.chats[foundChatIndex].lastMessage = newMsg;
          this.chats = [...this.chats];
        }
        if (newMsg?.username === this.username) {
          return;
        }
        if (
          newMsg
          && window.location.search !== `?chat=${chatId}`
          && !this.chats.find((chat) => chat.chatId === chatId)?.offNotifyUsernames?.includes(
            userStore.currentUser?.username ?? '-'
          )
        ) {
          messageNotificationsStore.addMessage(
            newMsg
          )
          this.messagesCout += 1;
          if (newMsg.id) {
            apolloClient.mutate<{deliveredMessage: Message}>({
              mutation: deliveredMessage,
              variables: { id: newMsg.id },
            });
          }

          showNotification(
            `${newMsg.userPicture?.firstname} ${newMsg.userPicture?.lastname}`,
            { body: newMsg.text ?? 'Нет текста' }
          )
            .then((notification) => {
              notification?.addEventListener
              && notification.addEventListener('click', () => {
                window.focus();
                window.location.href = `/messages?chat=${newMsg.chatId}`
              });
            });
        } else {
          if (
            newMsg.id
            && window.location.search === `?chat=${chatId}`
          ) {
            this.readMessage(newMsg.id);
          }
        }
      },
      error: (error) => console.error(error),
    });
  }

  private createMessageUpdateStream = () => {
    // const token = sessionStorage.getItem(SESSION_STORAGE_JWT_TOKEN);
    apolloClient.subscribe<{onUpdateMessage: Message}>({
      query: onUpdateMessage,
      // context: { headers: { Authorization: token ? `Bearer ${token}` : undefined } },
    })
      .filter((value) =>
        !!value.data?.onUpdateMessage &&
        !!this.chats.find((chat) => chat.chatId === value.data?.onUpdateMessage?.chatId)
      )
      .map((value) => value.data?.onUpdateMessage)
      .filter((value) => !!value && isMessage(value))
      .subscribe({
        next: (updateMsg) => {
          const chatId = updateMsg?.chatId;
          if (!chatId || !this.messages[chatId]) {
            return;
          }
          const foundChatIndex = this.chats.findIndex((chat) => chat.chatId === chatId);
          if (this.chats[foundChatIndex]?.lastMessage?.id === updateMsg.id) {
            this.chats[foundChatIndex].lastMessage = updateMsg;
            this.chats = [...this.chats];
          }
          this.messages[chatId] = [...this.messages[chatId].map((msg) => {
            if (msg.id !== updateMsg.id) {
              return msg
            }
            return updateMsg;
          })];
        },
        error: (error) => console.error(error),
      });
  }

  createTypingChatStream = () => {
    // const token = sessionStorage.getItem(SESSION_STORAGE_JWT_TOKEN);
    apolloClient.subscribe<{onTyping: Chat}>({
      query: onTyping,
      // context: { headers: { Authorization: token ? `Bearer ${token}` : undefined } },
    })
      .filter((value) =>
        !!value.data?.onTyping &&
        !!value.data.onTyping.usernames?.find((uname) => uname === this.username)
      )
      .map((value) => value.data?.onTyping)
      .filter((value) =>
        !!value && isChat(value)
      ).subscribe({
        next: (typingChat) => {
          if (!typingChat?.chatId) {
            return;
          }
          this.chats = this.chats.map((chat) => typingChat.chatId === chat.chatId ? typingChat : chat);
        },
        error: (error) => console.error(error),
      });
  }

  readMessage = async (id: string): Promise<Message|null> => {
    const res = await apolloClient.mutate<{readMessage: Message}>({
      mutation: readMessage,
      variables: { id },
    });
    return res.data?.readMessage ?? null;
  }

  readMessageList = async (ids: string[]): Promise<(Message|null)[]> => {
    const res = await apolloClient.mutate<{readMessageList: (Message|null)[]}>({
      mutation: readMessageList,
      variables: { ids },
    });
    return res.data?.readMessageList ?? [];
  }

  deliveredMessageList = async (ids: string[]): Promise<(Message|null)[]> => {
    const res = await apolloClient.mutate<{deliveredMessageList: (Message|null)[]}>({
      mutation: deliveredMessageList,
      variables: { ids },
    });
    return res.data?.deliveredMessageList ?? [];
  }

  createChat = async (input: CreateChatInput): Promise<Chat|null> => {
    const res = await apolloClient.mutate<{createChat: Chat}>({
      mutation: createChat,
      variables: { input },
    });

    return res.data?.createChat ?? null
  }

  createMessage = async (input: CreateMessageInput): Promise<Message|null> => {
    const res = await apolloClient.mutate<{createMessage: Message}>({
      mutation: createMessage,
      variables: { input },
    });

    return res.data?.createMessage ?? null;
  }

  sendMessage = async (input: CreateMessageInput, repeatId?: string): Promise<boolean> => {
    try {
      this.loading = true;
      const message = await this.createMessage(input);

      if (!message) {
        this.loading = false;
        return false;
      }

      if (repeatId) {
        this.messages[input.chatId] = this.messages[input.chatId].filter((msg) => msg.id !== repeatId)
      }
    } catch {
      const dateNow = new Date().getTime();
      if (!repeatId) {
        const message: Message = {
          __typename: 'Message',
          id: `error-${uuid()}`,
          chatId: input.chatId,
          text: input.text,
          media: input.media,
          delivered: ['$error'],
          username: userStore.currentUser?.username,
          createdAt: dateNow,
          updatedAt: dateNow,
        }
        this.messages[input.chatId] = [message, ...this.messages[input.chatId]];
      }
      this.loading = false;
      return false;
    }

    this.loading = false;
    return true;
  }

  async makeReaction(id: string, type: string) {
    const res = await apolloClient.mutate<{makeReaction: Message}>({
      mutation: makeReaction,
      variables: { input: {
        messageId: id,
        type,
       } }
    });
    const message = res?.data?.makeReaction ?? null;

    if (!message?.chatId) {
      return;
    }

    const index = this.messages[message.chatId]
      ?.findIndex((msg) => msg.id === message.id) ?? -1;
    if (index === -1) {
      return;
    }

    this.messages[message.chatId] = [
      ...this.messages[message.chatId].map((msg) => {
        return msg.id !== message.id
          ? msg
          : { ...message }
      })
    ]
  }

  async switchNotification(chatId: string, isOn: boolean) {
    try {
      const chat = isOn
        ? (await apolloClient.mutate<{switchOffNotifications: Chat}>({
          mutation: switchOffNotifications,
          variables: { chatId },
        })).data?.switchOffNotifications
        : (await apolloClient.mutate<{switchOnNotifications: Chat}>({
          mutation: switchOnNotifications,
          variables: { chatId },
        })).data?.switchOnNotifications;

      if (!chat) {
        return
      }
      const chatInList = this.chats.find((chat) => chat.chatId === chatId);

      if (!chatInList) {
        return;
      }
      this.chats = [...this.chats.filter((chat) => chat.chatId !== chatId), chat];

      if (isOn) {
        toastsStore.addNotifyToast(this.t('common:toasts.success.notifyOff'), false)
      } else {
        toastsStore.addNotifyToast(this.t('common:toasts.success.notifyOn'), true)
      }
    } catch {
      toastsStore.addErrorToast(this.t('common:toasts.error.notify'))
    }
  }

  blockUser = async (username: string) => {
    try {
      const block = (await apolloClient.mutate<{blockUser: BlockChatUser}>({
        mutation: blockUser,
        variables: { username },
      })).data?.blockUser;

      if (block) {
        const index = this.chats.findIndex((chat) => chat.usernames?.includes(username));
        const userIndex = this.chats[index].userPicture?.findIndex((user) => user?.username === username) ?? -1;
        if (this.chats[index]?.userPicture?.[userIndex]) {
          this.chats[index].userPicture![userIndex]!.isBlocked = true;
        }
        this.chats = [...this.chats];

        toastsStore.addSuccessToast(this.t('common:toasts.success.userBloked'))
        return;
      }
    } catch {}

    toastsStore.addErrorToast(this.t('common:toasts.error.userBloked'));
  }

  unblockUser = async (username: string) => {
    try {
      const block = (await apolloClient.mutate<{unblockUser: BlockChatUser}>({
        mutation: unblockUser,
        variables: { username },
      })).data?.unblockUser;

      if (block) {
        const index = this.chats.findIndex((chat) => chat.usernames?.includes(username));
        const userIndex = this.chats[index].userPicture?.findIndex((user) => user?.username === username) ?? -1;
        if (this.chats[index]?.userPicture?.[userIndex]) {
          this.chats[index].userPicture![userIndex]!.isBlocked = false;
        }
        this.chats = [...this.chats];

        toastsStore.addSuccessToast(this.t('common:toasts.success.userUnbloked'))
        return;
      }
    } catch {}

    toastsStore.addErrorToast(this.t('common:toasts.error.userUnbloked'));
  }

  deleteChat = async (chatId: string) => {
    try {
      const deletedChat = (await apolloClient.mutate<{deleteChat: Chat}>({
        mutation: deleteChat,
        variables: { chatId },
      })).data?.deleteChat;

      if (deletedChat) {
        this.chats = this.chats.filter((chat) => chat.chatId !== deletedChat.chatId);
        this.messages[chatId] = [];
        toastsStore.addSuccessToast(this.t('common:toasts.success.chatDelete'));
        return;
      }
    } catch {}

    toastsStore.addErrorToast(this.t('common:toasts.error.chatDelete'));
  }

  startTyping = async (chatId: string) => {
    try {
      const typingChat = (await apolloClient.mutate<{startTyping: Chat}>({
        mutation: startTyping,
        variables: { chatId },
      })).data?.startTyping;
    } catch {}
  }

  stopTyping = async (chatId: string) => {
    try {
      const typingChat = (await apolloClient.mutate<{stopTyping: Chat}>({
        mutation: stopTyping,
        variables: { chatId },
      })).data?.stopTyping;
    } catch {}
  }
}

export const messagesStore = new MessagesStore();
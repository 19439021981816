import styles from "./main-right.module.css";
import { Trends } from "../trends/trends.comp";
import { userStore } from "@store";
import { LoginRightComp } from "../login-right/login-right.comp";
import { observer } from "mobx-react-lite";
import { useLocation } from "react-router-dom";
import { LinkComponent } from "@ui-kit";
import { PATHES_WITH_RIGHT } from "./knownPaths";
import { useTranslation } from "react-i18next";

export const MainRight = observer(() => {

  const { t } = useTranslation('main');

  const location = useLocation();

  const isAdminPath =  location.pathname.startsWith('/admin-reporter');

  const { currentUser } = userStore;

  if (!PATHES_WITH_RIGHT.includes(location.pathname.split('/')?.[1])) {
    return <></>
  }

  return (
    <div className={styles.right}>
      {!isAdminPath && (
        <>
          <div className={styles.header_back} />
            {!!currentUser?.token ? (
              <Trends />
            ) : (
              <LoginRightComp />
            )}
          <div className={styles.documents}>
            <LinkComponent typeLink='gray' className={styles.link} link='/privacy-policy'>
              {t('mainRight.links.privacy')}
            </LinkComponent>
            <LinkComponent typeLink='gray' className={styles.link} link='/agreement'>
              {t('mainRight.links.agreement')}
            </LinkComponent>
            <LinkComponent typeLink='gray' className={styles.link} link='/terms'>
              {t('mainRight.links.terms')}
            </LinkComponent>
          </div>
        </>
      )}
    </div>
  );
});
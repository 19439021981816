import { makeAutoObservable, runInAction } from 'mobx';
import { CreateCityInput } from '@graphql/graphql';

interface RegistrationData {
  firstname: string;
  lastname: string;
  username: string;
  phone: string;
  email: string;
  password: string;
  passwordRepeat: string;
  whatsapp: boolean;
  emailapp: boolean;
}

class RegistrationStore {
  data: RegistrationData = {
    firstname: '',
    lastname: '',
    username: '',
    phone: '',
    email: '',
    password: '',
    passwordRepeat: '',
    whatsapp: false,
    emailapp: false
  };

  city?: CreateCityInput;
  isSendedCode = false;

  constructor() {
    makeAutoObservable(this);
  }

  get whatsapp() {
    return this.data.phone.trim() !== '';
  }

  get emailapp() {
    return this.data.email.trim() !== '';
  }

  setData<Key extends keyof RegistrationData>(key: Key, value: RegistrationData[Key]) {
    runInAction(() => {
      this.data = { ...this.data, [key]: value };

      this.data.whatsapp = this.whatsapp;
      this.data.emailapp = this.emailapp;
    });
  }

  setAllData(newData: Partial<RegistrationData>) {
    runInAction(() => {
      this.data = { ...this.data, ...newData };

      this.data.whatsapp = this.whatsapp;
      this.data.emailapp = this.emailapp;
    });
  }

  getData() {
    return this.data;
  }

  setIsSendedCode(value: boolean) {
    runInAction(() => {
      this.isSendedCode = value;
    });
  }

  reset() {
    Object.assign(this.data, {
      firstname: '',
      lastname: '',
      username: '',
      phone: '',
      email: '',
      password: '',
      passwordRepeat: '',
      whatsapp: false,
      emailapp: false,
    });
    this.city = undefined;
    this.isSendedCode = false;
  }

  setCity(city: CreateCityInput | undefined) {
    this.city = city;
  }

  getCity() {
    return this.city;
  }
}

export const registrationStore = new RegistrationStore();
import { Post } from "@graphql/graphql";
import styles from "./post-with-commets.module.css";
import { getCorrectPostDate } from "../helpers/get-correct-post-date";
import { useEffect, useRef, useState } from "react";
import { Avatar, Button } from "@ui-kit";
import { ReactionsComponent } from "../reactions/reactions.comp";
import { commentListStore, userStore } from "@store";
import { CreateComment } from "../create-comment/create-comment.comp";
import { CommentList } from "../../comment-list/comment-list.comp";
import { convertHTMLTextToCorrectText } from "@shared/helpers/convert-html-text-to-correct-text";
import { ContentData } from "@shared/components/content-data/content-data.comp";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ReactionsSummaryComponent } from "../reactions-summary/reactions-summary.comp";

type Props = {
  post?: Post,
  showPictures?: boolean,
  showComments?: boolean,
  className?: string,
  onShareClick: () => void,
}

export const PostWithCommets = ({ post, showPictures, showComments, className, onShareClick }: Props) => {
  const { currentUser } = userStore;
  const navigate = useNavigate();

  const { t } = useTranslation('main');

  const [fullText, setFullText] = useState(false);
  const [showMoreButton, setShowMoreButton] = useState(false);
  const [showReactions, setShowReactions] = useState(false);
  const textRef = useRef<any>();
  const postTextRef = useRef<any>();

  className = className ? `${className} ${styles['post-with-commets']}` : styles['post-with-commets'];

  useEffect(() => {
    setTimeout(() => {
      setShowMoreButton(
        postTextRef.current.scrollWidth > postTextRef.current.offsetWidth
        || postTextRef.current.scrollHeight > postTextRef.current.offsetHeight
      );
    }, 10);
  }, [postTextRef]);

  const onFocusWithUsername = (username?: string) => {
    if (
      username &&
      !convertHTMLTextToCorrectText(commentListStore.text)
        .split('\n')
        .map((line: string) => line.split(' '))
        .flat()
        .find((word: string) => word === `@${username}`)
    ) {
      commentListStore.setText(`@${username}&nbsp;${commentListStore.text}`);
      textRef.current.innerHTML = commentListStore.text;
    }

    textRef.current.focus();
    const selection = window.getSelection();
    const range = document.createRange();
    try {
      range.setStart(textRef.current, commentListStore.text.split('\n').length);
      range.setEnd(textRef.current, commentListStore.text.split('\n').length);
      selection?.removeAllRanges();
      selection?.addRange(range);
    } catch {}
  }

  if (!post) {
    return <div className={className} />
  }

  return <div className={className}>
    <div className={styles['post-with-commets__header']}>
      <Avatar
        className={styles['post-with-commets__avatar']}
        size='lg'
        isDeleted={!post?.userPicture}
        url={post?.userPicture?.picture?.url ?? undefined}
        statusIcon={post.userPicture?.isOnline ? 'online' : undefined} />
      <div className={styles['post-with-commets__top-right']}>
        <Avatar
          className={styles['post-with-commets__avatar-mobile']}
          size='xs'
          isDeleted={!post?.userPicture}
          url={post?.userPicture?.picture?.url ?? undefined}
          statusIcon={post.userPicture?.isOnline ? 'online' : undefined} />
        <div className={styles['post-with-commets__name']}>
          {post?.userPicture?.firstname} {post?.userPicture?.lastname}
        </div>
        <div className={styles['post-with-commets__second-text']}>
          <p className={styles['post-with-commets__city']}>{post?.city?.longName}</p>
          <div className={styles['post-with-commets__dot']}></div>
          <p className={styles['post-with-commets__time']}> {post ? getCorrectPostDate(post).relativeTime : ''}</p>
        </div>
      </div>
    </div>
    <div
      ref={postTextRef}
      className={
        fullText
          ? `${styles['post-with-commets__text']} ${styles['post-with-commets__text_full']}`
          : styles['post-with-commets__text']
      }
    >
      <ContentData text={post.text ?? ''} usernames={Array.isArray(post.answer) ? post.answer : []} />
    </div>
    {showMoreButton && <Button
      className={styles['post-with-commets__more-button']}
      size='lg'
      hierarchy='link-gray'
      onClick={() => setFullText((prev) => !prev)}
    >
      {!fullText ? t('postComments.read') : t('postComments.hide')}
    </Button>}

    <ReactionsSummaryComponent inZoom={true} item={post} showReactions={showReactions} />

    <div className={styles['post-with-commets__bottom']}>
    <div className={styles['post__reactions-conteiner']}
        onMouseEnter={() => !!currentUser && setShowReactions(true)}
        onMouseLeave={() => !!currentUser && setShowReactions(false)}>
      <ReactionsComponent inZoom={true} item={post} showReactions={showReactions} />
      <Button className={styles['post__with-commets-button']} onClick={() => !!currentUser && setShowReactions((prev) => !prev)} size='sm' hierarchy='primary-gray' iconType='thumbs-up'>{t('post.button.reaction')}</Button>
      </div>
      <Button className={styles['post__with-commets-button']}
          onClick={() => { navigate(`/post/${post?.id}`) }}
          size='sm'
          hierarchy='primary-gray'
          iconType='message-circle'
        >{t('post.button.comment')}</Button>
      <Button className={styles['post__with-commets-button']} onClick={onShareClick} size='3xl' hierarchy='primary-gray' iconType='share'>{t('post.button.share')}</Button>
    </div>
    <CreateComment post={post} className={styles['create-comment']} textRef={textRef} />
    <CommentList
      className={styles['post-with-commets__comment-list']}
      postId={post.id}
      scrollParentLevel={2}
      hideOnTabletAndMobile={true}
      onFocusWithUsername={onFocusWithUsername} />
  </div>
}
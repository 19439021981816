import { Badge } from "@ui-kit/components/";
import styles from "./city-cover.module.css";
import { AvatarAddButton } from "@ui-kit/components/avatar-add-button/avatar-add-button.comp";
import { useEffect, useState } from "react";
import { citiesStore, modalCustomDialogsStore, toastsStore, userStore } from "@store";
import { observer } from "mobx-react-lite";
import { CropCityCoverDialog } from "../crop-city-cover-dialog/crop-city-cover-dialog.comp";
import { loadCoverPictureToDB } from "@home/helpers/load-cover-picture-to-db";
import { useTranslation } from "react-i18next";

export const CityCover = observer(() => {
  const { currentCity } = citiesStore;

  const userPictureUrl = currentCity?.userPicture?.picture?.url ?? undefined;
  const fullname = `${currentCity?.userPicture?.firstname ?? ''} ${currentCity?.userPicture?.lastname ?? ''}`;

  const [loading, setLoading] = useState<boolean>(false);

  const [picture, setPicture] = useState<string|undefined>();

  const { t } = useTranslation('main');

  let canChangeCover = userStore.currentUser?.roles?.some(role => role?.name === "admin");

  const handleFileChange = (event: any) => {
    if (event.target.files[0]) {
      const inputFile = event.target.files[0];
      const imgUrl = URL.createObjectURL(inputFile);

      const onSuccsesCrop = async (inputFile: ImageBitmap) => {
        try {
          await loadCoverPictureToDB(inputFile, event.target.value)
        } catch(err: any) {
          setPicture(() => currentCity?.picture?.url ?? undefined);
          toastsStore.addErrorToast(t('toasts.error.loadMedia'));
          console.error(err);
        } finally {
          modalCustomDialogsStore.hideDialog();
        };
      }

      modalCustomDialogsStore.showDialog(
        <CropCityCoverDialog
          image={imgUrl}
          onSuccsesCrop={onSuccsesCrop} />,
          {}
        );
    }
  };

  useEffect(() => {
    if (currentCity?.picture?.url) {
      setPicture(currentCity.picture.url);
    }
  }, [currentCity?.picture?.url]);

  const overClass = currentCity?.picture?.url
    ? styles['cover-changer_autohide']
    : styles['cover-changer_show']

  return currentCity ? (
    <div style={{ backgroundImage: `url('${picture}')` }} className={styles['city-cover']}>
      {canChangeCover && <div className={`${styles['cover-changer']} ${overClass}`}>
        <div className={styles['cover-changer__content']}>
          <p className={styles['cover-changer__text']}>
            {picture ? t('cityCover.change') : t('cityCover.add')}
          </p>
          <AvatarAddButton className={styles['cover-changer__button']} handleFileChange={handleFileChange} />
        </div>
      </div>}
      <div className={styles.pioner}>
        <Badge size='sm' color='primary' icon='avatar' iconUrl={userPictureUrl}>{t('cityCover.installed')}{fullname}</Badge>
        <Badge size='sm' color='primary' icon='icon-left' iconType={'help-circle'} onClick={() => {}} />
      </div>
    </div>
  ) : (
    <div className={styles['city-cover']} />
  );
});
